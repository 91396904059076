import React from "react";
import ScrollToHashElement from "./ScrollToHashElement";
import Hero from "../../component/Hero/Hero";
import About from "../section/about/about";
import Newlaunch from "../section/newlaunch/newlaunch";
import Promo from "../section/promo/promo";
import Produk from "../section/produk/produk";
import Fasilitas from "../../component/fasilitas/fasilitas";
import Surrounding from "../section/surrounding/surrounding.jsx";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Hero />
      <About />
      <Newlaunch />
      <Promo />
      <Produk />
      <Fasilitas />
      <Surrounding />
    </div>
  );
};

export default home;
