import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "./ScrollToTop.jsx";
import Navbar from "./component/navbar/navbar.jsx";
import Footer from "./pages/section/footer/footer.jsx";
import Privacy from "./pages/privacy/Privacy.jsx";
const App = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/#promo" element={<Home />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
        <Footer />
      </Router>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://api.whatsapp.com/send?phone=6285795865769&text=Halo%20Ines%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(https://marketing-kotabaruparahyangan.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
};

export default App;
